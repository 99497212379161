@import "~antd/dist/antd.css";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ant-list-item:hover {
  background-color: #f5f5f5;
}

.title {
  color: rgb(26, 0, 255);
  padding: 2.5px;
}

.player {
  text-align: right;
  border: 0px;
  width: 400px;
  height: 307px;
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 1;
}

@media (max-width: 700px) {
  .player {
    width: 100%;
    height: 120px;
  }

  .title {
    text-align: center;
  }

  .releases {
    padding-bottom: 307px;
  }
}
